import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { ComDropdownComponent } from 'src/app/components/com-dropdown/com-dropdown.component';
import { EntryDetailForPost, EntryPostRequest } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-chobun2',
  templateUrl: './chobun2.component.html',
  styleUrls: ['./chobun2.component.scss']
})
export class Chobun2Component implements OnInit {
  @ViewChild('chobunComboEm', { static: false }) chobunComboEm: ComDropdownComponent;
  @ViewChild('itemComboEm', { static: false }) itemComboEm: ComDropdownComponent;

  Const = CommonConstants;
  utils = Utils;
  seko_info = this.sessionSvc.get('custom_seko_info');
  chobun_edit: EntryDetailForPost = this.sessionSvc.get('chobun_edit');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  service = null;

  honbun_max_row = 10;
  honbun_max_row_length = 21;
  honbun_max_length = this.honbun_max_row * this.honbun_max_row_length;
  preview_daishi_file = null;
  wareki_list = null;
  item_list = null;

  host_url = window.location.origin;
  constructor(
    private router: Router,
    private httpClientService: HttpClientService,
    private sessionSvc: SessionService,
  ) { }


  itemCombo = { values: [], clearable: false, showOnFocus: false, disableSearch: true };
  chobunCombo = { placeholder: '定型文を選ぶ', values: [], clearable: false, showOnFocus: false, disableSearch: true };
  async ngOnInit() {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    await this.getSekoInfo(this.seko_info.id);
    if (this.seko_info.services) {
      this.service = this.seko_info.services.find(v => v.hall_service.id === this.Const.SERVICE_ID_CHOBUN);
    }
    if (!this.seko_info.services && !this.service) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (!this.chobun_edit || !this.chobun_edit.chobun || this.isExpierd()) {
      this.router.navigate(['chobun/entry1/']);
      return;
    }

    await this.getItem();
    await this.getChobunSample();
    if (!this.chobun_edit.chobun.atena) {
      this.chobun_edit.chobun.atena = this.seko_info.moshu.name;
    }
    this.getWarekiList();
    setTimeout(() => {
      $('.ui.radio.checkbox').checkbox();
    }, 100);
  }

  async getSekoInfo(id) {
    await this.httpClientService.getSekoFuhoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;

    }).catch(error => {
      return false;
    });
    return true;
  }

  imageLoadError(item) {
    item.image_file = null;
  }

  async getItem() {
    this.item_list = new Array();
    if (this.seko_info && this.seko_info.items) {
      this.seko_info.items.forEach(value => {
        if (value.item.service === this.Const.SERVICE_ID_CHOBUN &&
          Utils.isBetweenTerm(value.item.begin_date, value.item.end_date)) {
          value.item.image_file = this.host_url + '/media/items/' + this.seko_info.seko_company.id + '/' + value.item.hinban + '.jpg';
          const price = value.item.item_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.itemCombo.values.push({ name: value.item.name + '  ¥' + price, value: value.item.id, data: value });
          this.item_list.push(value.item);
        }
      });
      this.itemComboEm.initComponent();
    }
  }

  getWarekiList() {
    this.httpClientService.getWarekiList().then((response) => {
      Utils.log(response);
      this.wareki_list = response;
      for (const wareki of this.wareki_list) {
        wareki.begin_date = new Date(wareki.begin_date);
        wareki.end_date = new Date(wareki.end_date);
      }
    }).catch(error => {
    });
  }
  getWarekiYear(date) {
    if (!this.wareki_list) {
      return null;
    }
    return Utils.getYearDisplay(new Date(date), this.wareki_list, true, true);
  }
  getMonthKanji(date) {
    return Utils.numbersToKanji(new Date(date).getMonth() + 1) + '月';
  }
  getDateKanji(date) {
    return Utils.numbersToKanji(new Date(date).getDate()) + '日';
  }
  async getChobunSample() {
    await this.httpClientService.getChobunSampleList().then(async (response) => {
      Utils.log(response);
      if (response) {
        response.forEach(value => { value.sentence = value.sentence.replace(/\r\n/g, '\n'); });
        this.chobunCombo.values = response.map(value => ({ name: value.name, value: value.id, data: value }));
        this.chobunComboEm.initComponent();
      }
    }).catch(error => {
    });
  }

  itemChange(event) {
    if (event.data) {
      this.chobun_edit.item_hinban = event.data.item.hinban;
      this.chobun_edit.item_name = event.data.item.name;
      this.chobun_edit.item_unit_price = event.data.item.item_price;
      this.chobun_edit.item_tax_pct = event.data.item.tax.tax_pct;
      this.chobun_edit.keigen_flg = event.data.item.tax.keigen_flg;
      this.chobun_edit.quantity = 1;
      this.chobun_edit.item_tax = Utils.calcTax(
        this.chobun_edit.item_unit_price * this.chobun_edit.quantity,
        this.chobun_edit.item_tax_pct,
        this.seko_info.seko_company.calc_type
      );
    }
  }

  chobunChange(event) {
    if (event.data) {
      this.chobun_edit.chobun.honbun = event.data.sentence;
    }
  }

  saveData() {
    if (!this.invalidData()) {
      const scrollTo = $('.navigation').offset();
      $('body, html').animate({
        scrollTop: scrollTo.top
      }, 200);
      return;
    }
    if (!this.entry) {
      this.entry = new EntryPostRequest();
    }
    if (!this.entry.details) {
      this.entry.details = new Array();
    }
    if (this.chobun_edit.index !== undefined
      && this.chobun_edit.index !== null &&
      this.entry.details[this.chobun_edit.index]) {
      this.entry.details[this.chobun_edit.index] = this.chobun_edit;
    } else {
      this.chobun_edit.index = this.entry.details.length;
      this.entry.details.push(this.chobun_edit);
    }
    this.sessionSvc.save('service_entry', this.entry);
    this.sessionSvc.clear('chobun_edit');
    this.router.navigate(['cart/']);
  }
  invalidData() {
    $('.input').attr('err-msg', null);
    $('.daishi').attr('err-msg', null);
    if (!this.chobun_edit || !this.chobun_edit.chobun) {
      return false;
    }
    let ret = true;
    if (!this.chobun_edit.item) {
      $('.input.item_id').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.atena) {
      $('.input.atena').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.honbun) {
      $('.input.honbun').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.daishi) {
      $('.daishi').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!ret) {
      return ret;
    }
    const honbun_rows = this.chobun_edit.chobun.honbun.split('\n');
    if (honbun_rows.length > this.honbun_max_row) {
      $('.input.honbun').attr('err-msg', '*' + this.honbun_max_row + '行以内でご入力してください。');
      return false;
    }
    for (const honbun_row of honbun_rows) {
      if (honbun_row.length > this.honbun_max_row_length) {
        $('.input.honbun').attr('err-msg', '*1行に' + this.honbun_max_row_length + '文字以内でご入力してください。');
        return false;
      }
    }
    if (this.chobun_edit.chobun.honbun && this.chobun_edit.chobun.honbun.length > this.honbun_max_length) {
      $('.input.honbun').attr('err-msg', '*入力可能文字数を超えております');
      ret = false;
    }
    return ret;
  }

  selectDaishi(daishi_id) {
    this.chobun_edit.chobun.daishi = daishi_id;
  }

  showDaishiPreview() {
    if (!this.chobun_edit.chobun.daishi) {
      return;
    }
    const daishi = this.seko_info.seko_company.chobun_daishi_masters.find(
      v => v.id === this.chobun_edit.chobun.daishi
    );
    if (!daishi) {
      return;
    }
    this.preview_daishi_file = daishi.file_name;
    $('#daishi-preview').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  showItemList() {
    $('#item-list').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  selectItem(item) {
    if (item) {
      this.itemComboEm.setValue(item.id);
    }
    $('#item-list').modal('hide');
  }

  back() {
    this.router.navigate(['chobun/entry1/']);
  }
  isExpierd() {
    if (!this.service) {
      return true;
    }
    return this.service.is_expired;
  }

  addressConvert(text: string) {
    if (!text) {
      return '';
    }
    return Utils.addressConvNumber(text).replace(/－/g, 'ー').replace(/-/g, 'ー');
  }
}
