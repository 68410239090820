import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { EntryPostRequest, EntryDetailForPost, HenreihinKodenForPost } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-koden2',
  templateUrl: './koden2.component.html',
  styleUrls: ['./koden2.component.scss']
})
export class Koden2Component implements OnInit {
  Const = CommonConstants;
  seko_info = this.sessionSvc.get('custom_seko_info');
  koden_edit: EntryDetailForPost = this.sessionSvc.get('koden_edit');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  service = null;
  item_list = null;
  selected_item = null;

  host_url = window.location.origin;

  message = null;
  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
  ) { }

  async ngOnInit() {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    await this.getSekoInfo(this.seko_info.id);
    if (this.seko_info.services) {
      this.service = this.seko_info.services.find(v => v.hall_service.id === this.Const.SERVICE_ID_KODEN);
    }
    if (!this.seko_info.services && !this.service) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (!this.koden_edit || !this.koden_edit.koden || this.isExpierd()) {
      this.router.navigate(['koden/entry1/']);
      return;
    }
    this.getItem();
  }

  async getSekoInfo(id) {
    await this.httpClientService.getSekoFuhoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;

    }).catch(error => {
      return false;
    });
    return true;
  }

  imageLoadError(item) {
    item.image_file = null;
  }

  async getItem() {
    this.item_list = new Array();
    if (this.seko_info && this.seko_info.items) {
      this.seko_info.items.forEach(value => {
        if (value.item.service === this.Const.SERVICE_ID_HENREIHIN &&
          Utils.isBetweenTerm(value.item.begin_date, value.item.end_date) &&
          value.item.item_price / (100 + value.item.tax.tax_pct) * 100 <= this.koden_edit.item_unit_price / this.seko_info.henreihin_rate) {
          value.item.image_file = this.host_url + '/media/items/' + this.seko_info.seko_company.id + '/' + value.item.hinban + '.jpg';
          this.item_list.push(value.item);
          if (this.koden_edit.koden.henrei_koden && this.koden_edit.koden.henrei_koden.henreihin === value.item.id) {
            this.selected_item = value.item;
          }
        }
      });
      if (!this.selected_item) {
        delete this.koden_edit.koden.henrei_koden;
      }
    }
  }
  checkChange() {
    this.koden_edit.koden.henreihin_fuyo_flg = !this.koden_edit.koden.henreihin_fuyo_flg;
    if (this.koden_edit.koden.henreihin_fuyo_flg) {
      this.selected_item = null;
      delete this.koden_edit.koden.henrei_koden;
    }

  }
  saveData() {
    if (!this.invalidData()) {
      const scrollTo = $('.navigation').offset();
      $('body, html').animate({
        scrollTop: scrollTo.top
      }, 200);
      return;
    }
    if (!this.entry) {
      this.entry = new EntryPostRequest();
    }
    if (!this.entry.details) {
      this.entry.details = new Array();
    }
    if (this.koden_edit.index !== undefined
      && this.koden_edit.index !== null &&
      this.entry.details[this.koden_edit.index]) {
      this.entry.details[this.koden_edit.index] = this.koden_edit;
    } else {
      this.koden_edit.index = this.entry.details.length;
      this.entry.details.push(this.koden_edit);
    }
    this.sessionSvc.save('service_entry', this.entry);
    this.sessionSvc.clear('koden_edit');
    this.router.navigate(['cart/']);
  }

  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.koden_edit || !this.koden_edit.koden) {
      return false;
    }
    let ret = true;
    if (!this.koden_edit.koden.henreihin_fuyo_flg && !this.koden_edit.koden.henrei_koden) {
      $('.input.item').attr('err-msg', '*必須項目です');
      ret = false;
    }
    return ret;
  }

  showItemList() {
    $('#item-list').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  selectItem(item) {
    this.selected_item = item;
    if (item) {
      this.koden_edit.koden.henreihin_fuyo_flg = false;
      if (!this.koden_edit.koden.henrei_koden) {
        this.koden_edit.koden.henrei_koden = new HenreihinKodenForPost();
      }
      this.koden_edit.koden.henrei_koden.henreihin = item.id;
      this.koden_edit.koden.henrei_koden.henreihin_hinban = item.hinban;
      this.koden_edit.koden.henrei_koden.henreihin_name = item.name;
      this.koden_edit.koden.henrei_koden.henreihin_price = item.item_price;
      this.koden_edit.koden.henrei_koden.henreihin_tax_pct = item.tax.tax_pct;
      this.koden_edit.koden.henrei_koden.keigen_flg = item.tax.keigen_flg;
      this.koden_edit.koden.henrei_koden.customer_self_select_flg = true;
      this.koden_edit.koden.henrei_koden.select_status = 2;
      this.koden_edit.koden.henrei_koden.henreihin_tax = Utils.calcTax(
        item.item_price, item.tax.tax_pct, this.seko_info.seko_company.calc_type
      );
    }
    $('#item-list').modal('hide');
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  back() {
    this.router.navigate(['koden/entry1/']);
  }

  isExpierd() {
    if (!this.service) {
      return true;
    }
    return this.service.is_expired;
  }

}
