import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClientService } from 'src/app/service/http-client.service';
import { SessionService } from 'src/app/service/session.service';
import { EntryPostRequest, EntryDetailForPost, EntryDetailKodenForPost } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;


@Component({
  selector: 'app-cus-koden1',
  templateUrl: './koden1.component.html',
  styleUrls: ['./koden1.component.scss']
})
export class Koden1Component implements OnInit {

  Const = CommonConstants;
  seko_info = this.sessionSvc.get('custom_seko_info');
  koden_edit: EntryDetailForPost = this.sessionSvc.get('koden_edit');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  service = null;
  item = null;
  price_max = 50;
  price_input = false;
  description = `パソコン、スマートフォンから、喪主に香典を送ることができます。
注文に関するトラブルが発生した場合、お客様と葬儀社様での解決をお願いしております。`;

  priceCombo = {
    values: [
      { value: 3, name: '3' },
      { value: 5, name: '5' },
      { value: 10, name: '10' },
      { value: 30, name: '30' },
      { value: -1, name: 'その他' },
    ],
    clearable: false,
    showOnFocus: false,
    disableSearch: true
  };
  patameter = null;

  message = null;

  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
  ) { }

  async ngOnInit() {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    await this.getSekoInfo(this.seko_info.id);
    if (!this.seko_info.seko_company.focfee || !this.seko_info.seko_company.focfee.koden_fee) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (this.seko_info.services) {
      this.service = this.seko_info.services.find(v => v.hall_service.id === this.Const.SERVICE_ID_KODEN);
    }
    if (!this.seko_info.services || !this.service) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (this.seko_info.items) {
      this.item = this.seko_info.items.find(v => v.item.service === this.Const.SERVICE_ID_KODEN);
    }
    if (!this.seko_info.items || !this.item) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (this.entry && this.entry.details) {
      for (const detail of this.entry.details) {
        if (detail.kumotsu || detail.message || detail.chobun) {
          this.message = '※ご香典お預かりサービスは他のサービスと同時に申込することができません。';
          return;
        }
        if (detail.koden && !this.koden_edit) {
          this.message = '※ご香典お預かりサービスは複数件同時に申込することができません。';
          return;
        }
      }
    }
    await this.getParameter();
    if (!this.koden_edit) {
      this.koden_edit = new EntryDetailForPost();
    }
    if (!this.koden_edit.koden) {
      this.koden_edit.koden = new EntryDetailKodenForPost();
    }
    this.koden_edit.service = this.service.hall_service;
    this.koden_edit.item = this.item.item.id;
    this.koden_edit.item_hinban = this.item.item.hinban;
    this.koden_edit.item_name = this.item.item.name;
    this.koden_edit.quantity = 1;
    this.koden_edit.item_tax_pct = 0;
    this.koden_edit.item_tax = 0;
    this.koden_edit.keigen_flg = false;
    this.koden_edit.koden.koden_commission_tax_pct = 0;
    if (this.patameter) {
      this.koden_edit.koden.koden_commission_tax_pct = this.patameter.koden_commission_tax_pct;
    }
    this.koden_edit.koden.tax_adjust = 0;
    if (!this.koden_edit.item_unit_price) {
      this.koden_edit.item_unit_price = 0;
    }
    if (this.koden_edit.koden.selected_price === -1) {
      this.price_input = true;
    }
    this.calcCommission();
  }

  async getParameter() {
    await this.httpClientService.getKodenParameter().then((response) => {
      Utils.log(response);
      this.patameter = response;
      this.price_max = this.patameter.koden_upper_limit / 1000;
    }).catch(error => {
    });
  }

  async getSekoInfo(id) {
    await this.httpClientService.getSekoFuhoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;

    }).catch(error => {
      return false;
    });
    return true;
  }

  selectPriceChange(event) {
    if (event) {
      if (event === -1) {
        this.price_input = true;
        this.koden_edit.item_unit_price = 0;
      } else {
        this.price_input = false;
        this.koden_edit.koden.other_price = null;
        this.koden_edit.item_unit_price = event * 1000;
      }
      this.calcCommission();
    }
  }
  otherPriceChange() {
    this.koden_edit.item_unit_price = this.koden_edit.koden.other_price * 1000;
    this.calcCommission();
  }

  calcCommission() {
    this.koden_edit.koden.koden_commission =
      Math.floor(this.koden_edit.item_unit_price * this.seko_info.seko_company.focfee.koden_fee / 100);
    this.koden_edit.koden.koden_commission_tax = Utils.calcTax(
      this.koden_edit.koden.koden_commission,
      this.koden_edit.koden.koden_commission_tax_pct,
      this.seko_info.seko_company.calc_type
    );

  }
  saveData() {
    if (!this.invalidData()) {
      const scrollTo = $('.navigation').offset();
      $('body, html').animate({
        scrollTop: scrollTo.top
      }, 200);
      return;
    }

    if (this.service && this.service.henreihin_select_flg && this.haveHenreihinItem()) {
      this.sessionSvc.save('koden_edit', this.koden_edit);
      this.router.navigate(['koden/entry2/']);
    } else {
      if (!this.entry) {
        this.entry = new EntryPostRequest();
      }
      if (!this.entry.details) {
        this.entry.details = new Array();
      }
      this.koden_edit.koden.henreihin_fuyo_flg = true;
      delete this.koden_edit.koden.henrei_koden;
      if (this.koden_edit.index !== undefined
        && this.koden_edit.index !== null &&
        this.entry.details[this.koden_edit.index]) {
        this.entry.details[this.koden_edit.index] = this.koden_edit;
      } else {
        this.koden_edit.index = this.entry.details.length;
        this.entry.details.push(this.koden_edit);
      }
      this.sessionSvc.save('service_entry', this.entry);
      this.sessionSvc.clear('koden_edit');
      this.router.navigate(['cart/']);
    }
  }
  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.koden_edit || !this.koden_edit.koden) {
      return false;
    }
    let ret = true;
    if (!this.koden_edit.item_unit_price) {
      if (!this.price_input) {
        $('.input.price').attr('err-msg', '*必須項目です');
      } else {
        $('.input.other_price').attr('err-msg', '*必須項目です');
      }
      ret = false;
    }
    if (this.koden_edit.item_unit_price > this.price_max * 1000) {
      $('.input.other_price').attr('err-msg', '*入力可能範囲を超えております');
      ret = false;
    }
    return ret;
  }

  haveHenreihinItem() {
    if (this.seko_info && this.seko_info.items) {
      for (const value of this.seko_info.items) {
        if (value.item.service === this.Const.SERVICE_ID_HENREIHIN &&
          Utils.isBetweenTerm(value.item.begin_date, value.item.end_date) &&
          value.item.item_price / (100 + value.item.tax.tax_pct) * 100 <= this.koden_edit.item_unit_price / this.seko_info.henreihin_rate) {
          return true;
        }
      }
    }
    return false;
  }
  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  back() {
    this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
  }

  isExpierd() {
    if (!this.service) {
      return true;
    }
    return this.service.is_expired;
  }

}
