import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClientService } from 'src/app/service/http-client.service';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-fam-soke-approve',
  templateUrl: './soke-approve.component.html',
  styleUrls: ['./soke-approve.component.scss']
})
export class SokeApproveComponent implements OnInit {

  approve_flg = 0;
  company_id = null;
  seko_id = null;
  err_msg = null;
  complete_msg = null;
  is_loading = false;
  CONST_REQUIRE = '葬儀番号が正しくありません。';
  CONST_NOTFUOND = 'ご指定の葬儀情報が見つかりません。';
  CONST_APPROVE_ERROR = '申込み処理が失敗しました。';
  CONST_COMPLETE = 'ご葬家専用ページの申込みが完了しました。';
  CONST_ALREDY_COMPLETE = 'すでに申込みが完了しております。';
  complete = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpClientService: HttpClientService,
  ) { }

  async ngOnInit() {
    this.is_loading = true;
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const paramId = params.get('id');
      if (!paramId) {
        return;
      }
      const paramIds = paramId.split('-');
      if (paramIds.length >= 1) {
        this.company_id = Number(paramIds[0]);
      }
      if (paramIds.length >= 2) {
        this.seko_id = Number(paramIds[1]);
        return;
      }

    });
    await this.getSekoInfo();
    setTimeout(() => {
      $('.ui.checkbox').checkbox();
    }, 100);

  }
  approveSeko() {
    if (!this.seko_id) {
      return;
    }
    this.httpClientService.approveSokeSite(this.seko_id).then((response) => {
      Utils.log(response);
      if (!response) {
        this.err_msg = this.CONST_APPROVE_ERROR;
        return;
      }
      this.err_msg = this.CONST_COMPLETE;
      this.complete_msg = 'ログインID及びパスワードは、ご指定のメールアドレス宛にて送信しましたのでメールをご確認下さい。';
      this.complete = true;

    }).catch(error => {
      this.err_msg = this.CONST_APPROVE_ERROR;
    });

  }

  async getSekoInfo() {
    if (!this.company_id || !this.seko_id) {
      this.err_msg = this.CONST_REQUIRE;
      this.is_loading = false;
      return;
    }
    this.is_loading = true;
    await this.httpClientService.getSekoFuhoByid(this.seko_id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      if (!sekoResponse || sekoResponse.seko_company.id !== Number(this.company_id)) {
        this.err_msg = this.CONST_NOTFUOND;
        this.is_loading = false;
        return;
      }
      if (sekoResponse.moshu.agree_ts) {
        this.err_msg = this.CONST_ALREDY_COMPLETE;
        this.complete = true;
      }
      this.is_loading = false;

    }).catch(error => {
      this.err_msg = this.CONST_NOTFUOND;
      this.is_loading = false;
    });
  }
  goLogin() {
    this.router.navigate(['soke/'], {
      queryParams: { id: this.seko_id },
    });
  }
}
