import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { HttpClientService } from 'src/app/service/http-client.service';
import { EntryPostRequest, EntryDetailForPost,
  EntryDetailChobunForPost, EntryDetailCommon
} from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-chobun1',
  templateUrl: './chobun1.component.html',
  styleUrls: ['./chobun1.component.scss']
})
export class Chobun1Component implements OnInit {

  Const = CommonConstants;
  utils = Utils;
  seko_info = this.sessionSvc.get('custom_seko_info');
  chobun_edit: EntryDetailForPost = this.sessionSvc.get('chobun_edit');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  service = null;
  description = `パソコン、スマートフォンから、ご葬儀に直接弔文が送信できます。
文字数は210文字まで。定型文等もご用意しております。
弔文は印刷してお届けします。
ご注文に関するトラブルが発生した場合、お客様と葬儀社様での解決をお願いしております。`;

  message = null;

  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private zipcodeSvc: ZipcodeService,
    private httpClientService: HttpClientService,
  ) { }

  async ngOnInit() {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    await this.getSekoInfo(this.seko_info.id);
    if (this.seko_info.services) {
      this.service = this.seko_info.services.find(v => v.hall_service.id === this.Const.SERVICE_ID_CHOBUN);
    }
    if (!this.seko_info.services || !this.service) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (this.entry && this.entry.details) {
      for (const detail of this.entry.details) {
        if (detail.koden) {
          this.message = '※ご香典お預かりサービスと同時に申込することができません。';
          return;
        }
      }
    }
    if (!this.chobun_edit) {
      this.chobun_edit = new EntryDetailForPost();
    }
    if (!this.chobun_edit.chobun) {
      this.chobun_edit.chobun = new EntryDetailChobunForPost();
      this.initData();
    }
    this.chobun_edit.service = this.service.hall_service;
  }

  initData() {
    if (!this.entry || !this.entry.details) {
      return;
    }
    let init_data: EntryDetailCommon = null;
    for (const detail of this.entry.details) {
      if (detail.chobun) {
        init_data = detail.chobun;
        break;
      } else if (detail.kumotsu) {
        init_data = detail.kumotsu;
        break;
      }
    }
    if (init_data) {
      this.chobun_edit.chobun.okurinushi_company = init_data.okurinushi_company;
      this.chobun_edit.chobun.okurinushi_title = init_data.okurinushi_title;
      this.chobun_edit.chobun.okurinushi_company_kana = init_data.okurinushi_company_kana;
      this.chobun_edit.chobun.okurinushi_name = init_data.okurinushi_name;
      this.chobun_edit.chobun.okurinushi_kana = init_data.okurinushi_kana;
      this.chobun_edit.chobun.okurinushi_tel = init_data.okurinushi_tel;
      this.chobun_edit.chobun.okurinushi_zip_code = init_data.okurinushi_zip_code;
      this.chobun_edit.chobun.okurinushi_prefecture = init_data.okurinushi_prefecture;
      this.chobun_edit.chobun.okurinushi_address_1 = init_data.okurinushi_address_1;
      this.chobun_edit.chobun.okurinushi_address_2 = init_data.okurinushi_address_2;
      this.chobun_edit.chobun.okurinushi_address_3 = init_data.okurinushi_address_3;
      this.chobun_edit.chobun.renmei1 = init_data.renmei1;
      this.chobun_edit.chobun.renmei_kana1 = init_data.renmei_kana1;
      this.chobun_edit.chobun.renmei2 = init_data.renmei2;
      this.chobun_edit.chobun.renmei_kana2 = init_data.renmei_kana2;
    }
  }

  async getSekoInfo(id) {
    await this.httpClientService.getSekoFuhoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;

    }).catch(error => {
      return false;
    });
    return true;
  }

  saveData() {
    if (!this.invalidData()) {
      const scrollTo = $('.navigation').offset();
      $('body, html').animate({
        scrollTop: scrollTo.top
      }, 200);
      return;
    }
    this.sessionSvc.save('chobun_edit', this.chobun_edit);
    this.router.navigate(['chobun/entry2/']);
  }
  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.chobun_edit || !this.chobun_edit.chobun) {
      return false;
    }
    let ret = true;
    if (!this.chobun_edit.chobun.okurinushi_name) {
      $('.input.okurinushi_name').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.okurinushi_kana) {
      $('.input.okurinushi_kana').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.okurinushi_tel) {
      $('.input.okurinushi_tel').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.okurinushi_zip_code) {
      $('.input.okurinushi_zip_code').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.okurinushi_prefecture) {
      $('.input.okurinushi_prefecture').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.okurinushi_address_1) {
      $('.input.okurinushi_address_1').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.chobun_edit.chobun.okurinushi_address_2) {
      $('.input.okurinushi_address_2').attr('err-msg', '*必須項目です');
      ret = false;
    }
    return ret;
  }

  zipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.chobun_edit.chobun.okurinushi_zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.chobun_edit.chobun.okurinushi_prefecture = address.prefecture;
        this.chobun_edit.chobun.okurinushi_address_1 = address.address_1;
        this.chobun_edit.chobun.okurinushi_address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  back() {
    this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
  }

  isExpierd() {
    if (!this.service) {
      return true;
    }
    return this.service.is_expired;
  }
}
