import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { HttpClientService } from 'src/app/service/http-client.service';
import { EntryPostRequest } from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';

declare var $;

@Component({
  selector: 'app-cus-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  Const = CommonConstants;

  seko_info = this.sessionSvc.get('custom_seko_info');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  sum_tax_8 = { price: 0, tax: 0, tax_sum: 0 };
  sum_tax_10 = { price: 0, tax: 0, tax_sum: 0 };
  sum_tax_0 = { price: 0 };
  delete_index = -1;
  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private httpClientService: HttpClientService,
  ) { }

  ngOnInit(): void {
    this.calcSummary();
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    this.getSekoInfo();
  }

  calcSummary() {
    this.sum_tax_8.price = 0;
    this.sum_tax_8.tax = 0;
    this.sum_tax_8.tax_sum = 0;
    this.sum_tax_10.price = 0;
    this.sum_tax_10.tax = 0;
    this.sum_tax_10.tax_sum = 0;
    this.sum_tax_0.price = 0;
    if (!this.entry || !this.entry.details || !this.entry.details.length) {
      return;
    }
    for (const detail of this.entry.details) {
      detail.tax_adjust = 0;
      if (detail.item_tax_pct === 8) {
        this.sum_tax_8.price += detail.item_unit_price * detail.quantity;
        this.sum_tax_8.tax_sum += detail.item_tax;
      } else if (detail.item_tax_pct === 10) {
        this.sum_tax_10.price += detail.item_unit_price * detail.quantity;
        this.sum_tax_10.tax_sum += detail.item_tax;
      } else {
        this.sum_tax_0.price += detail.item_unit_price * detail.quantity;
      }
      if (detail.koden) {
        if (detail.koden.koden_commission_tax_pct === 8) {
          this.sum_tax_8.price += detail.koden.koden_commission;
          this.sum_tax_8.tax_sum += detail.koden.koden_commission_tax;
        } else if (detail.koden.koden_commission_tax_pct === 10) {
          this.sum_tax_10.price += detail.koden.koden_commission;
          this.sum_tax_10.tax_sum += detail.koden.koden_commission_tax;
        } else {
          this.sum_tax_0.price += detail.koden.koden_commission;
        }

      }
    }
    this.sum_tax_8.tax = Utils.calcTax(this.sum_tax_8.price, 8, this.seko_info.seko_company.calc_type);
    this.sum_tax_10.tax = Utils.calcTax(this.sum_tax_10.price, 10, this.seko_info.seko_company.calc_type);
    if (this.sum_tax_8.tax !== this.sum_tax_8.tax_sum) {
      for (const detail of this.entry.details) {
        if (detail.item_tax_pct === 8) {
          detail.tax_adjust = this.sum_tax_8.tax - this.sum_tax_8.tax_sum;
          break;
        }
      }
    }
    if (this.sum_tax_10.tax !== this.sum_tax_10.tax_sum) {
      for (const detail of this.entry.details) {
        if (detail.item_tax_pct === 10) {
          detail.tax_adjust = this.sum_tax_10.tax - this.sum_tax_10.tax_sum;
          break;
        }
      }
    }
    this.entry.billing_amount = this.sum_tax_8.price + this.sum_tax_10.price + this.sum_tax_0.price;

  }

  getSekoInfo() {
    this.httpClientService.getSekoFuhoByid(this.seko_info.id).then((sekoResponse) => {
      if (!Utils.isBetweenTerm(null, String(sekoResponse.fuho_site_end_date))) {
        this.sessionSvc.clearCustomerData();
        this.router.navigate(['fuho/']);
        return;
      }
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;
      this.sessionSvc.save('custom_seko_info', this.seko_info);

    }).catch(error => {
    });
  }

  editDetail(detail) {
    switch (detail.service.id) {
      case this.Const.SERVICE_ID_CHOBUN:
        this.sessionSvc.save('chobun_edit', detail);
        this.router.navigate(['chobun/entry1/']);
        return;
      case this.Const.SERVICE_ID_KUMOTSU:
        this.sessionSvc.save('kumotsu_edit', detail);
        this.router.navigate(['kumotsu/entry/']);
        return;
      case this.Const.SERVICE_ID_KODEN:
        this.sessionSvc.save('koden_edit', detail);
        this.router.navigate(['koden/entry1/']);
        return;
      case this.Const.SERVICE_ID_MESSAGE:
        this.sessionSvc.save('message_edit', detail);
        this.router.navigate(['message/entry/']);
        return;
      default:
        return;
    }

  }

  getServiceName(service) {
    if (!service) {
      return '';
    }
    switch (service.id) {
      case this.Const.SERVICE_ID_CHOBUN:
        return '続けて弔文を送る';
      case this.Const.SERVICE_ID_KUMOTSU:
        return '続けて供花・供物を送る';
      case this.Const.SERVICE_ID_KODEN:
        return 'ご香典預かりサービスを利用する';
      case this.Const.SERVICE_ID_MESSAGE:
        return '追悼メッセージを送る';
      default:
        return '';
    }
  }
  goServiceLink(service) {
    if (!service) {
      return;
    }
    switch (service.id) {
      case this.Const.SERVICE_ID_CHOBUN:
        this.sessionSvc.clear('chobun_edit');
        this.router.navigate(['chobun/entry1/']);
        return;
      case this.Const.SERVICE_ID_KUMOTSU:
        this.sessionSvc.clear('kumotsu_edit');
        this.router.navigate(['kumotsu/entry/']);
        return;
      case this.Const.SERVICE_ID_KODEN:
        this.sessionSvc.clear('koden_edit');
        this.router.navigate(['koden/entry1/']);
        return;
      case this.Const.SERVICE_ID_MESSAGE:
        this.sessionSvc.clear('messageedit');
        this.router.navigate(['message/entry/']);
        return;
      default:
        return;
    }
  }
  isNotInService(service_id, item_id = -1) {
    return !(this.seko_info.services.find(v => v.hall_service.id === service_id));

  }
  isNotInServiceTerm(service_id, item_id = -1) {
    const service = this.seko_info.services.find(v => v.hall_service.id === service_id);
    if (service.hall_service.id !== this.Const.SERVICE_ID_KUMOTSU) {
      return this.isExpierd(service);
    }
    if (!service.is_expired) {
      return false;
    }
    const item = this.seko_info.items.find(v => (v.item.id === item_id && v.seko_after_flg));
    if (!item) {
      return true;
    }
    return false;

  }
  iteminvalid(item_id) {
    if (!item_id) {
      return false;
    }
    const item = this.seko_info.items.find(v => v.item.id === item_id);
    if (!item) {
      return true;
    }
    return !Utils.isBetweenTerm(item.item.begin_date, item.item.end_date);

  }
  canOrder() {
    if (!this.entry || !this.entry.details || !this.entry.details.length) {
      return false;
    }
    for (const detail of this.entry.details) {
      if (this.isNotInService(detail.service.id) ||
        this.isNotInServiceTerm(detail.service.id, detail.item) ||
        this.iteminvalid(detail.item)) {
        return false;
      }
    }
    return true;
  }
  deleteConfirm(detail) {
    if (!detail) {
      return;
    }
    this.delete_index = detail.index;
    $('#delete-confirm').modal({
      closable: false,
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');


  }
  cancelConfirm() {

    $('#delete-confirm').modal('hide');
  }
  deleteData() {

    this.entry.details.splice(this.delete_index, 1);
    let index = 0;
    for (const detail of this.entry.details) {
      detail.index = index;
      index++;
    }
    this.calcSummary();
    this.sessionSvc.save('service_entry', this.entry);
    $('#delete-confirm').modal('hide');
  }
  saveData() {
    if (!this.canOrder()) {
      return;
    }
    this.entry.seko = this.seko_info.id;
    this.sessionSvc.save('service_entry', this.entry);
    this.router.navigate(['order/entry']);
  }
  canContinue(service) {
    if (this.entry && this.entry.details) {
      for (const detail of this.entry.details) {
        if (detail.kumotsu || detail.message || detail.chobun) {
          if (service.id === this.Const.SERVICE_ID_KODEN) {
            return false;
          }
        }
        if (detail.koden) {
          return false;
        }
      }
    }
    return true;
  }
  isExpierd(service) {
    if (!service) {
      return true;
    }
    if (service.hall_service.id === this.Const.SERVICE_ID_KUMOTSU) {
      return false;
    }
    return service.is_expired;
  }
}
