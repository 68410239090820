import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/service/session.service';
import { ZipcodeService } from 'src/app/service/zipcode.service';
import { AddressGetRequest } from 'src/app/interfaces/master';
import { HttpClientService } from 'src/app/service/http-client.service';
import { EntryPostRequest, EntryDetailForPost,
  EntryDetailKumotsuForPost, EntryDetailCommon
} from 'src/app/interfaces/order';
import { CommonConstants } from 'src/app/const/const';
import { Utils } from 'src/app/const/func-util';
declare var $;

@Component({
  selector: 'app-cus-kumotsu',
  templateUrl: './kumotsu.component.html',
  styleUrls: ['./kumotsu.component.scss']
})
export class KumotsuCusComponent implements OnInit {
  Const = CommonConstants;
  utils = Utils;
  seko_info = this.sessionSvc.get('custom_seko_info');
  kumotsu_edit: EntryDetailForPost = this.sessionSvc.get('kumotsu_edit');
  entry: EntryPostRequest = this.sessionSvc.get('service_entry');
  service = null;
  item_list = null;
  selected_item = null;

  description = `パソコン、スマートフォンから、ご葬儀に供花、供物のご発注ができます。
注文に関するトラブルが発生した場合、お客様と葬儀社様での解決をお願いしております。`;

  quantityCombo = {
    values: [
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' },
      { value: 4, name: '4' },
      { value: 5, name: '5' },
      { value: 6, name: '6' },
      { value: 7, name: '7' },
      { value: 8, name: '8' },
      { value: 9, name: '9' },
    ],
    clearable: false,
    showOnFocus: false,
    disableSearch: true
  };

  host_url = window.location.origin;

  message = null;
  constructor(
    private router: Router,
    private sessionSvc: SessionService,
    private zipcodeSvc: ZipcodeService,
    private httpClientService: HttpClientService,
  ) { }

  async ngOnInit() {
    if (!this.seko_info) {
      this.router.navigate(['fuho/']);
      return;
    }
    await this.getSekoInfo(this.seko_info.id);
    if (this.seko_info.services) {
      this.service = this.seko_info.services.find(v => v.hall_service.id === this.Const.SERVICE_ID_KUMOTSU);
    }
    if (!this.seko_info.services && !this.service) {
      this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
      return;
    }
    if (this.entry && this.entry.details) {
      for (const detail of this.entry.details) {
        if (detail.koden) {
          this.message = '※ご香典お預かりサービスと同時に申込することができません。';
          return;
        }
      }
    }
    if (!this.kumotsu_edit) {
      this.kumotsu_edit = new EntryDetailForPost();
      this.kumotsu_edit.quantity = 1;
    }
    if (!this.kumotsu_edit.kumotsu) {
      this.kumotsu_edit.kumotsu = new EntryDetailKumotsuForPost();
      this.initData();
    }
    this.kumotsu_edit.service = this.service.hall_service;
    this.getItem();
  }

  async getSekoInfo(id) {
    await this.httpClientService.getSekoFuhoByid(id).then((sekoResponse) => {
      Utils.log(sekoResponse);
      Utils.formatSekoDate(sekoResponse);
      this.seko_info = sekoResponse;

    }).catch(error => {
      return false;
    });
    return true;
  }

  initData() {
    if (!this.entry || !this.entry.details) {
      return;
    }
    let init_data: EntryDetailCommon = null;
    for (const detail of this.entry.details) {
      if (detail.chobun) {
        init_data = detail.chobun;
        break;
      } else if (detail.kumotsu) {
        init_data = detail.kumotsu;
        break;
      }
    }
    if (init_data) {
      this.kumotsu_edit.kumotsu.okurinushi_company = init_data.okurinushi_company;
      this.kumotsu_edit.kumotsu.okurinushi_title = init_data.okurinushi_title;
      this.kumotsu_edit.kumotsu.okurinushi_company_kana = init_data.okurinushi_company_kana;
      this.kumotsu_edit.kumotsu.okurinushi_name = init_data.okurinushi_name;
      this.kumotsu_edit.kumotsu.okurinushi_kana = init_data.okurinushi_kana;
      this.kumotsu_edit.kumotsu.okurinushi_tel = init_data.okurinushi_tel;
      this.kumotsu_edit.kumotsu.okurinushi_zip_code = init_data.okurinushi_zip_code;
      this.kumotsu_edit.kumotsu.okurinushi_prefecture = init_data.okurinushi_prefecture;
      this.kumotsu_edit.kumotsu.okurinushi_address_1 = init_data.okurinushi_address_1;
      this.kumotsu_edit.kumotsu.okurinushi_address_2 = init_data.okurinushi_address_2;
      this.kumotsu_edit.kumotsu.okurinushi_address_3 = init_data.okurinushi_address_3;
      this.kumotsu_edit.kumotsu.renmei1 = init_data.renmei1;
      this.kumotsu_edit.kumotsu.renmei_kana1 = init_data.renmei_kana1;
      this.kumotsu_edit.kumotsu.renmei2 = init_data.renmei2;
      this.kumotsu_edit.kumotsu.renmei_kana2 = init_data.renmei_kana2;
    }
  }

  imageLoadError(item) {
    item.image_file = null;
  }

  async getItem() {
    this.item_list = new Array();
    if (this.seko_info && this.seko_info.items) {
      this.seko_info.items.forEach(value => {
        if (value.item.service === this.Const.SERVICE_ID_KUMOTSU &&
          Utils.isBetweenTerm(value.item.begin_date, value.item.end_date) &&
          ((!this.isExpierd() && value.seko_before_flg) || (this.isExpierd() && value.seko_after_flg))) {
          value.item.image_file = this.host_url + '/media/items/' + this.seko_info.seko_company.id + '/' + value.item.hinban + '.jpg';
          this.item_list.push(value.item);
          if (this.kumotsu_edit.item && this.kumotsu_edit.item === value.item.id) {
            this.selected_item = value.item;
          }
        }
      });
    }
  }

  saveData() {
    if (!this.invalidData()) {
      const scrollTo = $('.navigation').offset();
      $('body, html').animate({
        scrollTop: scrollTo.top
      }, 200);
      return;
    }
    if (!this.entry) {
      this.entry = new EntryPostRequest();
    }
    if (!this.entry.details) {
      this.entry.details = new Array();
    }
    if (this.isExpierd()) {
      this.kumotsu_edit.kumotsu.order_note = 'お届け先：' + this.seko_info.seko_contact.name + ' 様\n';
      this.kumotsu_edit.kumotsu.order_note += '〒' + this.seko_info.seko_contact.zip_code + '　'
                                          + this.seko_info.seko_contact.prefecture + this.seko_info.seko_contact.address_1;
      if (this.seko_info.seko_contact.address_2) {
        this.kumotsu_edit.kumotsu.order_note += this.seko_info.seko_contact.address_2;
      }
      if (this.seko_info.seko_contact.address_3) {
        this.kumotsu_edit.kumotsu.order_note += this.seko_info.seko_contact.address_3;
      }
      this.kumotsu_edit.kumotsu.order_note += '\n';
      if (this.seko_info.seko_contact.tel) {
        this.kumotsu_edit.kumotsu.order_note += '電話番号：' + this.seko_info.seko_contact.tel + '　';
      }
      if (this.seko_info.seko_contact.mobile_num) {
        this.kumotsu_edit.kumotsu.order_note += '携帯番号：' + this.seko_info.seko_contact.mobile_num;
      }
    }
    if (this.kumotsu_edit.index !== undefined
      && this.kumotsu_edit.index !== null &&
      this.entry.details[this.kumotsu_edit.index]) {
      this.entry.details[this.kumotsu_edit.index] = this.kumotsu_edit;
    } else {
      this.kumotsu_edit.index = this.entry.details.length;
      this.entry.details.push(this.kumotsu_edit);
    }
    this.sessionSvc.save('service_entry', this.entry);
    this.sessionSvc.clear('kumotsu_edit');
    this.router.navigate(['cart/']);
  }
  invalidData() {
    $('.input').attr('err-msg', null);
    if (!this.kumotsu_edit || !this.kumotsu_edit.kumotsu) {
      return false;
    }
    let ret = true;
    if (!this.kumotsu_edit.item) {
      $('.input.item').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.kumotsu_edit.kumotsu.okurinushi_name) {
      $('.input.okurinushi_name').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.kumotsu_edit.kumotsu.okurinushi_kana) {
      $('.input.okurinushi_kana').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.kumotsu_edit.kumotsu.okurinushi_tel) {
      $('.input.okurinushi_tel').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.kumotsu_edit.kumotsu.okurinushi_zip_code) {
      $('.input.okurinushi_zip_code').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.kumotsu_edit.kumotsu.okurinushi_prefecture) {
      $('.input.okurinushi_prefecture').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.kumotsu_edit.kumotsu.okurinushi_address_1) {
      $('.input.okurinushi_address_1').attr('err-msg', '*必須項目です');
      ret = false;
    }
    if (!this.kumotsu_edit.kumotsu.okurinushi_address_2) {
      $('.input.okurinushi_address_2').attr('err-msg', '*必須項目です');
      ret = false;
    }
    return ret;
  }
  quantityChange(event) {
    if (event && event.value !== null && this.selected_item) {
      this.kumotsu_edit.item_tax = Utils.calcTax(
        this.kumotsu_edit.item_unit_price * Number(event.value),
        this.kumotsu_edit.item_tax_pct,
        this.seko_info.seko_company.calc_type
      );
    }

  }
  showItemList() {
    $('#item-list').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  selectItem(item) {
    this.selected_item = item;
    if (item) {
      this.kumotsu_edit.item = item.id;
      this.kumotsu_edit.item_hinban = item.hinban;
      this.kumotsu_edit.item_name = item.name;
      this.kumotsu_edit.item_unit_price = item.item_price;
      this.kumotsu_edit.item_tax_pct = item.tax.tax_pct;
      this.kumotsu_edit.keigen_flg = item.tax.keigen_flg;
      this.kumotsu_edit.item_tax = Utils.calcTax(
        this.kumotsu_edit.item_unit_price * this.kumotsu_edit.quantity,
        this.kumotsu_edit.item_tax_pct,
        this.seko_info.seko_company.calc_type
      );
    }
    $('#item-list').modal('hide');
  }

  showFudaPreview(file) {
    $('#fuda-preview').modal({
      centered: false,
      detachable: false,
      transition: 'fade',
    }).modal('show');
  }

  zipcodeChange() {
    const request = new AddressGetRequest();
    request.zip_code = this.kumotsu_edit.kumotsu.okurinushi_zip_code;
    this.zipcodeSvc.getData(request).then(address => {
      if (address) {
        this.kumotsu_edit.kumotsu.okurinushi_prefecture = address.prefecture;
        this.kumotsu_edit.kumotsu.okurinushi_address_1 = address.address_1;
        this.kumotsu_edit.kumotsu.okurinushi_address_2 = address.address_2;
      }
    }).catch(error => { });
  }

  isErrorField(element) {
    return !!element.getAttribute('err-msg');
  }

  back() {
    this.router.navigate(['fuho/' + this.seko_info.seko_company.id + '-' + this.seko_info.id + '/']);
  }

  isExpierd() {
    if (!this.service) {
      return true;
    }
    return this.service.is_expired;
  }

}
